.card {
  width: 60% !important;
  padding: 15px 3px;
  margin-bottom: 50px;
}

.video-title {
  width: 60% !important;
}

@media only screen and (max-width: 1100px) {
  .card {
    width: 70% !important;
  }
  .video-title {
  width: 70% !important;
}
}

@media only screen and (max-width: 900px) {
  .card {
    width: 80% !important;
  }
  .video-title {
  width: 80% !important;
}
}

@media only screen and (max-width: 600px) {
  .card {
    width: 90% !important;
  }
  .video-title {
  width: 90% !important;
}
}

@media only screen and (max-width: 400px) {
  .card {
    width: 98% !important;
  }
  .row {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
}
