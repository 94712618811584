.p {
  font-size: 14px;
  color: rgb(255, 253, 253);
}

.sect {
  width: 100%;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  background: url('https://wallpaperaccess.com/full/1899390.jpg') center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .sect {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 300px) {
  .sect {
    margin-top: 200px;
  }
}

@media only screen and (max-width: 200px) {
  .sect {
    margin-top: 300px;
  }
}

.main-heading {
  color: rgb(218, 190, 190) font-size 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.x {
  display: block;
  width: 50%;
  min-height: 50%;
  justify-content: center;
}
.underline {
  height: 04px;
  width: 4rem;
  background-color: antiquewhite;
  margin-top: 10px;
  margin-bottom: 10px;
}
.bg-c-light {
  background-color: blanchedalmond;
}
h1 {
  text-align: center;
}

.d-container {
  width: 1000px;
  min-height: 400px;
  border-radius: 5%;
  margin: 0 auto;
  text-align: center;
  background-color: rgb(96, 94, 91);

  opacity: 0.8;
  display: flex;
  bottom: -250px;
  justify-content: center;
  align-items: center;
}
/* .e-container{
    width: 100px;
    height: 100vh;
    border-radius: 5%;
    margin: 0 auto;
    text-align: center;
    background-color: blanchedalmond;
} */
.sml {
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 3vh;
  color: rgb(255, 255, 255);
}
.t {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  /* bottom: -100px; */
}
.fo {
  color: rgb(255, 255, 255);
}
.ba {
  background: url(../../components/resources/images/sub-banner.jpg) center;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
}

.z-4 {
  font-weight: bold;
}
.z-5 {
  font-size: 150%;
}
