/* #services {
  padding: 50px 0;
}

.work-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  margin-top: 50px;
}
.work1 {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: grid;
}
.work1 img {
  width: 70%;
  padding-left: 20%;
  margin: 5%;
  border-radius: 5px;
  display: flex;
  transition: transform 0.5s;
  justify-content: center;
  align-items: center;
}

.work1:hover img {
  transform: scale(1.1);
}
.work1:hover .layer {
  height: 100%;
}
.c7 {
  background-color: rgb(236, 231, 225);
  width: 75%;
}
.c8 {
  background-color: rgb(236, 231, 225);
  width: 90%;
}
.btn {
  display: block;
  margin: 50px auto;
  width: fit-content;
  border: 1px solid #ff004f;
  padding: 14px 50px;
  border-radius: 6px;
  text-decoration: none;
  color: white;
  transition: background 0.5s;
}
.btn:hover {
  background: #ff004f;
} */

.services-section {
  min-height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.service-container {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media only screen and (max-width: 1300px) {
  .service-container {
    width: 80%;
  }
}

@media only screen and (max-width: 1100px) {
  .service-container {
    width: 98%;
  }
}

@media only screen and (max-width: 900px) {
  .service-container {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.service-cards {
  width: 33.3%;
  margin: 20px 20px;
  padding: 10px 15px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 650px) {
  .service-cards {
    width: 50%;
  }
}

@media only screen and (max-width: 450px) {
  .service-cards {
    width: 70%;
  }
}

@media only screen and (max-width: 350px) {
  .service-cards {
    width: 80%;
  }
}

.service-cards > img {
  width: 60%;
  border-radius: 50%;
}

.service-cards > h3 {
  color: black;
}

.service-cards:hover img {
  transform: scale(1.1);
  cursor: pointer;
}
