/* #services{
    padding: 50px 0;
 
}
.wor-list{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px, 1fr));
    grid-gap: 40px;
    margin-top: 50px;
}
.wor{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    
}
.wor img{
    width: 100%;
    border-radius:10px;
    display: block;
    transition: transform 0.5s;
   
}
.layer{
    width: 100%;
    height: 0;
    background: linear-gradient(rgba(0,0,0,0.6),#5398e2);
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    font-size: 14px;
    transition: height 0.5s;

}
.layer h3{
    font-weight: 500;
    margin-bottom: 20px;
}
.layer a{
    margin-top: 20px;
    color: #ff004f;
    text-decoration: none;
    font-size: 18px;
    line-height: 60px;
    background: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
}
.wor:hover img{
    transform: scale(1.1);
}
.wor:hover .layer{
    height: 100%;
}
.btn{
    display: block;
    margin: 50px auto;
    width: fit-content;
    border: 1px solid #ff004f;
    padding: 14px 50px;
    border-radius: 6px;
    text-decoration: none;
    color: white;
    transition: background 0.5s;
}
.btn:hover{
    background:#ff004f
}

.see{
    justify-content: center;
    color: rgb(139, 139, 230);
    align-items: center;
    display: flex;
   
}
.widt{
    display: flex;
    justify-content: center;
    align-items:center;
    width:100%;
    min-height: 100vh;
  
}
.z-2{
    color:black;
} */

* {
  margin: 0;
  padding: 0;
}

.pro-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  padding: 100px auto;
}

.pro-row {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
}

.pro-card {
  width: 33.3%;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 30px 20px;
}

@media only screen and (max-width: 1200px) {
  .pro-row {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 1000px) {
  .pro-row {
    width: 98%;
    display: flex;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 900px) {
  .pro-row {
    width: 98%;
    flex-direction: column;
    align-items: center;
  }
  .pro-card {
    width: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .pro-card {
    width: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .pro-card {
    width: 98%;
  }
}



.pro-card-img > a > img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.pro-card-content {
  margin: 15px 20px;
  display: flex;
  flex-direction: column;
}

.pro-card-content > h4 {
  font-weight: 400;
}

.pro-text-mute {
  opacity: 0.7;
}
