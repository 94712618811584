.navbar {
  background-color: #494976;
  opacity: 0.7;
  min-height: 80px;
  width: 100%;
  display: flex;
  justify-content: center !important;
  font-size: 1.2rem;
  z-index: 999;
}

.navbar-container {
  width: 70%;
  display: flex !important;
  justify-content: space-between;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  float: left;
}

.navbar-logo:hover {
  color: #fff !important;
}

.nav-menu {
  display: flex;
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  color: #a098ff;
}

.activated {
  background-color: #fff;
  color: #494976;
  font-weight: 500;
  border-radius: 20px;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: fixed;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #19191a;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1000;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #09bef0;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}
