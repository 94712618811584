





.background11{
    background: url("https://s3.ap-southeast-1.amazonaws.com/propnex-xserver-img/pnimgs/fileservice/1000028/hq-meta/2106/1000028-hq-meta-1623409088-sell-confident.jpg") center;
background-attachment: fixed;
}
.d12{
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL6vEcOfMuxqWOZbJsiqZgdzh6DFZHIamtMAT8J2qo&s"); 
}
.n12{
    /* background-color: rgb(196, 195, 195); */
    width: 100%;
    min-height: 100%;
text-align: center;
   
}
.n13{
    /* text-align: center; */
    margin-left: 20%;
    /* margin-right:20% */
}

@media (max-width: 768px) {
    .min-height-100 {
      min-height: 100%;
    }
  }

.section23{
    /* width: 100%; */
    /* min-height: 100vh; */
 
    background: url("https://wallpaperaccess.com/full/1899390.jpg") center;
    background-attachment: fixed;
    /* background-size: cover; */
    /* display: flex; */
   
}