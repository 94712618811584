body {
  font-family: Arial, sans-serif;
}

.alert-container {
  position: fixed;
  top: 300px;
  right: 20px;
  width: 300px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.alert-bar {
  height: 5px;
  background-color: #007bff;
  width: 100%;
}

.alert-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  cursor: pointer;
  padding: 5px;
  margin-right: 5px;
}

@keyframes countdown {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.countdown-bar {
  height: 5px;
  background-color: #ccc;
  animation: countdown 10s linear forwards;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox']:checked + .alert-container {
  display: block;
}
