.features .features-item {
  color: rgba(var(--color-default-rgb), 0.8);
}

.features .features-item + .features-item {
  margin-top: 100px;
}

@media (max-width: 768px) {
  .features .features-item + .features-item {
    margin-top: 40px;
  }
}

.features .features-item h3 {
  color: var(--color-secondary);
  font-weight: 700;
  font-size: 26px;
}

.features .features-item .btn-get-started {
  background-color: var(--color-primary);
  color: var(--color-inverse);
  padding: 8px 30px 10px 30px;
  border-radius: 4px;
}

.features .features-item ul {
  list-style: none;
  padding: 0;
}

.features .features-item ul li {
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.features .features-item ul li:last-child {
  padding-bottom: 0;
}

.features .features-item ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--color-primary);
}

.features .features-item img {
  border: 6px solid var(--color-box-background);
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}

.features .features-item .features-img-bg {
  position: relative;
  justify-content: center;
  min-height: 500px;
}

@media (max-width: 640px) {
  .features .features-item .features-img-bg {
    min-height: 300px;
  }
}

.features .features-item .features-img-bg img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.features .features-item .image-stack {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
}

.features .features-item .image-stack .stack-back {
  grid-column: 4/-1;
  grid-row: 1;
  width: 100%;
  z-index: 1;
}

.features .features-item .image-stack .stack-front {
  grid-row: 1;
  grid-column: 1 / span 8;
  margin-top: 20%;
  width: 100%;
  z-index: 2;
}
.lp {
  background-color: rgba(239, 208, 208, 0.717);
  background-attachment: fixed;
  width: 100%;
}

.sa {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lpp {
  position: 'absolute';
}

.k-1 {
  background: rgba(0, 0, 0, 0.04)
    url('../../../components/resources/images/features.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.cent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 3px;
}
.cent h1 {
  text-align: center;
  color: white;
  font-family: 'Source Code Pro', monospace;
  text-transform: uppercase;
}

.character {
  background-image: url('');

  position: absolute;
  top: -80%;
  left: 30%;
}

.f-1 {
  font-size: 250%;
}
.f-2 {
  justify-content: center;
  align-items: center;
}

.feature-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 50vh;
  width: 100%;
  color: #fff;
  padding: 30px 20px;
}

.h-full {
  min-height: 50vh;
}