@media (min-width: 1025px) {
  .h-custom {
  height: 100vh !important;
  }
  }
  .card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
  }
  .card-registration .select-arrow {
  top: 13px;
  }
  
  /* .gradient-custom-2 { */
  /* fallback for old browsers */
  /* background: #a1c4fd; */
  
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: -webkit-linear-gradient(to right, rgba(161, 196, 253, 1), rgba(194, 233, 251, 1)); */
  
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* background: linear-gradient(to right, rgba(161, 196, 253, 1), rgba(194, 233, 251, 1)) */
  /* } */
  
  .bg {
  background-color: #ffffff;
  
 
  }
  @media (min-width: 992px) {
  .card-registration-2 .bg-indigo {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  }
  }
  @media (max-width: 991px) {
  .card-registration-2 .bg-indigo {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  }
  }
  /* .li{
    background: url(../../components/resources/images/bld.jpeg) center;
    background-size: cover;
  } */
  .la{
    color:#ffffff
  }
  .d-8{
    
    justify-content: center;
    align-items: center;
    padding-left: 10;
}